import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { getCookieByDomain } from "./utils";

const permissions = {
  platform_admin: ["create", "view", "edit", "delete"],
  space_admin: ["create", "view", "edit"],
  creator: ["create", "view", "edit"],
  viewer: ["view"],
};

export const getPermissions = (token, spaceId) => {
  if (!token) {
    throw new Error("JWT token is required");
  }

  const decodedToken = jwtDecode(token);
  const spaceDetails = decodedToken?.access?.access?.space_details || [];

  const isPlatformAdmin = spaceDetails.some((space) => {
    const privileges = JSON.parse(space.PRIVILEGES || "{}");
    return privileges.platform_admin;
  });

  if (isPlatformAdmin) {
    return {
      privileges: { platform_admin: true },
      actions: permissions.platform_admin,
    };
  }

  const matchingSpace = spaceDetails.find(
    (space) => space.SPACE_ID === spaceId
  );

  if (!matchingSpace) {
    throw new Error("No matching space found for the given space ID");
  }

  const privileges = JSON.parse(matchingSpace.PRIVILEGES || "{}");

  const actions = (() => {
    if (privileges.platform_admin) {
      return permissions.platform_admin;
    }
    if (privileges.space_admin) {
      return permissions.space_admin;
    }
    if (privileges.creator) {
      return permissions.creator;
    }
    if (privileges.viewer) {
      return permissions.viewer;
    }
    return [];
  })();

  return { privileges, actions };
};

export const usePermission = (spaceId, requiredAction) => {
  const accessToken = getCookieByDomain("crave_jwt_access_token");
  const currentSpace = useSelector((state) => state.defaultSpace.data);
  try {
    const { actions } = getPermissions(accessToken, spaceId || currentSpace);
    return actions.includes(requiredAction);
  } catch (error) {
    console.error("Permission Error:", error.message);
    return false;
  }
};

export const getSpaceAdminIds = () => {
  try {
    const accessToken = getCookieByDomain("crave_jwt_access_token");
    const decodedToken = jwtDecode(accessToken);
    const spaceDetails = decodedToken?.access?.access?.space_details || [];

    const spaceIds = spaceDetails
      .filter((space) => {
        const privileges = JSON.parse(space.PRIVILEGES);
        return privileges.space_admin === "true";
      })
      .map((space) => space.SPACE_ID);

    return spaceIds;
  } catch (error) {
    console.error("err", error.message);
    return [];
  }
};

export const getCurrentRole = (spaceId) => {
  const accessToken = getCookieByDomain("crave_jwt_access_token");
  try {
    const { privileges } = getPermissions(accessToken, spaceId);
    return privileges;
  } catch (error) {
    console.error("get current role error:", error.message);
    return false;
  }
};
