import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMenu } from "./Menu";
import SidebarLogo from "./SidebarLogo";
import { makeid } from "../../utils/utils";
import styles from "./aside.module.css";
import Cookies from "js-cookie";

const Aside = ({ autoToggleEnabled, setAutoToggleEnabled }) => {
  const menu = useMenu();
  const [isExpanded, setIsExpanded] = useState(
    Cookies.get("isExpanded") === "true" ? true : false || false
  );
  const [expandedMenu, setExpandedMenu] = useState(null);
  const space = useSelector((state) => state.defaultSpace);
  const location = useLocation();
  const currentUrl = location.pathname;

  const toggleMenu = (index) => {
    setExpandedMenu(expandedMenu === index ? null : index);
  };

  const handleIconClick = () => {
    setAutoToggleEnabled(!autoToggleEnabled);
    if (!autoToggleEnabled) {
      setIsExpanded(false);
      Cookies.set("isExpanded", false);
    } else {
      Cookies.set("isExpanded", true);
    }
  };

  const handleMouseEnter = () => {
    if (autoToggleEnabled) setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    if (autoToggleEnabled) setIsExpanded(false);
  };

  return (
    <aside
      className={`${styles.sidebar} ${
        isExpanded ? styles.expanded : styles.collapsed
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.brand}>
        <Link to="/" className="app-brand-link">
          <SidebarLogo hiddenText={!isExpanded} imageWidth={35} />
        </Link>
        {autoToggleEnabled && <span className={styles.ml20}></span>}
        <i
          className={`menu-icon tf-icons ti ti-arrow-bar-${
            autoToggleEnabled ? "right" : "left"
          } cursor-pointer`}
          onClick={handleIconClick}
          title="Toggle auto expand/collapse on hover"
        />
      </div>

      <ul className={isExpanded ? styles.menuList : styles.compressedMenuList}>
        {Object.entries(menu).map(([key, value], mainIndex) => (
          <React.Fragment key={key}>
            {key === "Space Gallery" && space ? (
              <>
                <li
                  className={`${styles.menuHeader} ${
                    isExpanded ? "" : "invisible"
                  }`}
                >
                  {key}
                </li>
                {value.map((item, index) => (
                  <li
                    key={makeid(6)}
                    className={`${styles.menuItem} ${
                      expandedMenu === `${index}${mainIndex}` ? styles.open : ""
                    } ${currentUrl === item.link ? styles.active : ""}`}
                  >
                    <Link
                      to={item.link}
                      className={styles.menuLink}
                      onClick={() => toggleMenu(`${index}${mainIndex}`)}
                      title={isExpanded ? "" : item.name}
                    >
                      {item.icon}
                      {isExpanded && (
                        <span className={styles.menuText}>{item.name}</span>
                      )}
                    </Link>
                    {item.submenu &&
                      expandedMenu === `${index}${mainIndex}` && (
                        <ul className={styles.subMenu}>
                          {item.submenu.map((subItem) => (
                            <li
                              key={makeid(7)}
                              className={`${styles.menuItem} ${
                                currentUrl === subItem.link ? styles.active : ""
                              }`}
                            >
                              <Link
                                to={subItem.link}
                                className={styles.menuLink}
                              >
                                <span>{subItem.name}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                  </li>
                ))}
              </>
            ) : (
              <>
                <li
                  className={`${styles.menuHeader} ${
                    isExpanded ? "" : "invisible"
                  } d-none`}
                >
                  {key}
                </li>
                {value.map((item, index) => (
                  <li
                    key={makeid(6)}
                    className={`${styles.menuItem} ${
                      expandedMenu === `${index}${mainIndex}` ? styles.open : ""
                    } ${currentUrl === item.link ? styles.active : ""}`}
                  >
                    <Link
                      to={item.link}
                      className={styles.menuLink}
                      onClick={() => toggleMenu(`${index}${mainIndex}`)}
                      title={isExpanded ? "" : item.name}
                    >
                      {item.icon}
                      {isExpanded && (
                        <span className={styles.menuText}>{item.name}</span>
                      )}
                    </Link>
                    {item.submenu &&
                      expandedMenu === `${index}${mainIndex}` && (
                        <ul className={styles.subMenu}>
                          {item.submenu.map((subItem) => (
                            <li
                              key={makeid(7)}
                              className={`${styles.menuItem} ${
                                currentUrl === subItem.link ? styles.active : ""
                              }`}
                            >
                              <Link
                                to={subItem.link}
                                className={styles.menuLink}
                              >
                                <span>{subItem.name}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                  </li>
                ))}
              </>
            )}
          </React.Fragment>
        ))}
      </ul>
    </aside>
  );
};

export default React.memo(Aside);
