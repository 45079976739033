import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Banner from "../../Components/Banner/Banner";
import {
  useDeleteUserMutation,
  useGetUsersQuery,
} from "../../services/userService";
import AddNewSpace from "./AddNewSpace";
import { useSelector } from "react-redux";
import ReactTable from "../../Components/ReactTable/ReactTable";
import Avatar from "../../Components/Avatar/Avatar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PostPerPage from "../../Components/PostPerPage/PostPerPage";
import { CubeSpinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert } from "../../Components/Alerts/Alerts";
import styles from "./spacemanage.module.css";
import SearchFilter from "../../Components/SearchFilter/SearchFilter";
import isEmpty from "validator/es/lib/isEmpty";
import {
  filterByVal,
  getStandardErrMsg,
  isPlatformAdminUser,
} from "../../utils/utils";
import {
  useDeleteSpaceMutation,
  useGetSpacesQuery,
} from "../../services/spaceService";
import { IconUserPlus } from "@tabler/icons-react";
import ShareModal from "./ShareModal";
import { useGetRolesQuery } from "../../services/roleService";
import { getSpaceAdminIds } from "../../utils/usePermission";
const MySwal = withReactContent(Swal);

const ManageSpace = () => {
  const currentSpace = useSelector((state) => state.defaultSpace.data);
  const spaceAdminIds = getSpaceAdminIds();
  const isPlatformAdmin = isPlatformAdminUser(currentSpace);
  const canEdit = isPlatformAdmin;
  const canUpdateUser = isPlatformAdmin;
  const canDelete = isPlatformAdmin;
  const canCreate = isPlatformAdmin;
  const [showAddNewUser, setShowAddNewUser] = useState(false);
  const [showManageUsers, setShowManageUsers] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [spaceId, setSpaceId] = useState();

  const [space, setSpace] = useState({
    name: "",
    type: "",
    desc: "",
  });

  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);

  const [addSpace, setAddSpace] = useState(true);

  const { data, error, isFetching } = useGetSpacesQuery();
  const [deleteSpace, deleteSpaceInfo] = useDeleteSpaceMutation();
  const {
    data: usersData = [],
    isFetching: isFetchingUsers,
    error: isUserError,
  } = useGetUsersQuery();

  const {
    data: usersRoles = [],
    isFetching: isFetchingRoles,
    error: isRoleError,
  } = useGetRolesQuery();

  const handleEdit = useCallback((spaceId, name, type, desc) => {
    setSpace({
      name: name,
      type: type,
      desc: desc,
      spaceId: spaceId,
    });
    setAddSpace(false);
    setShowAddNewUser(true);
  }, []);

  const manageSpaceUsers = useCallback((spaceId, selectedUsers) => {
    setSelectedUsers(selectedUsers);
    setSpaceId(spaceId);
    setAddSpace(false);
    setShowManageUsers(true);
  }, []);

  const handleDelete = useCallback(
    (user_email) => {
      MySwal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteSpace(user_email);
        }
      });
    },
    [deleteSpace]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ cell }) =>
          cell.row.original.description.length > 80
            ? cell.row.original.description.substring(0, 40) + "..."
            : cell.row.original.description,
      },
      {
        Header: "Actions",
        Cell: ({ cell }) => (
          <>
            <span
              title={
                canUpdateUser ||
                spaceAdminIds.includes(cell.row.original.space_id)
                  ? ""
                  : 'You don"t have update Permission'
              }
              className={
                canUpdateUser ||
                spaceAdminIds.includes(cell.row.original.space_id)
                  ? ""
                  : "permission-disabled"
              }
            >
              <Link
                to="#"
                className="text-body"
                onClick={() =>
                  manageSpaceUsers(
                    cell.row.original.space_id,
                    cell.row.original.users
                  )
                }
              >
                <i className="ti ti-user-plus ti-sm me-2"></i>
              </Link>
            </span>
            <span
              title={canEdit ? "" : 'You don"t have edit Permission'}
              className={canEdit ? "" : "permission-disabled"}
            >
              <Link
                to="#"
                className="text-body"
                onClick={() =>
                  handleEdit(
                    cell.row.original.space_id,
                    cell.row.original.name,
                    cell.row.original.type,
                    cell.row.original.description
                  )
                }
              >
                <i className="ti ti-edit ti-sm mx-2" />
              </Link>
            </span>
            <span
              title={canDelete ? "" : 'You don"t have delete Permission'}
              className={canDelete ? "" : "permission-disabled"}
            >
              <Link
                to="#"
                className="text-body delete-record"
                onClick={() => handleDelete(cell.row.original.space_id)}
              >
                <i className="ti ti-trash ti-sm mx-2" />
              </Link>
            </span>
          </>
        ),
      },
    ],
    [handleDelete, handleEdit, manageSpaceUsers, canDelete, canEdit]
  );

  useEffect(() => {
    if (!isEmpty(searchText) && data && data.length > 0) {
      const matchingRes = filterByVal(data, searchText);
      setFilteredPosts(matchingRes);
    } else if (data && data.length > 0) {
      setFilteredPosts([...data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  const handleAddNew = () => {
    setSpace({
      name: "",
      type: "",
      desc: "",
    });
    setAddSpace(true);
    setShowAddNewUser(true);
  };

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">
        <Banner>Manage Space</Banner>

        <div className="card mt-5">
          <div className="card-datatable">
            <div
              id="DataTables_Table_0_wrapper"
              className="dataTables_wrapper dt-bootstrap5 no-footer "
            >
              <div className="row me-2">
                <div className="col-md-2 m-auto">
                  <div className={styles.postsPerPage}>
                    <PostPerPage
                      limit={postsPerPage}
                      setPostPerPage={setPostPerPage}
                    />
                  </div>
                </div>
                <div className="col-md-10">
                  <div
                    className={`dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0 `}
                  >
                    <div className={styles.tableFilter}>
                      <SearchFilter
                        searchText={searchText}
                        setSearchText={setSearchText}
                      />
                    </div>
                    <span
                      title={
                        canCreate ? "" : 'You don"t have create Permission'
                      }
                      className={canCreate ? "" : "permission-disabled"}
                    >
                      <div className="dt-buttons btn-group flex-wrap">
                        {" "}
                        <button
                          className="btn btn-secondary add-new btn-primary waves-effect waves-light"
                          tabIndex={0}
                          type="button"
                          onClick={() => handleAddNew()}
                        >
                          <span>
                            <i className="ti ti-plus me-0 me-sm-1 ti-xs" />
                            <span className="d-none d-sm-inline-block">
                              Add New Space
                            </span>
                          </span>
                        </button>{" "}
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              {isFetching ? (
                <div className={styles.cubeLoader}>
                  {" "}
                  <CubeSpinner />
                </div>
              ) : error ? (
                <>
                  <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
                </>
              ) : (
                <ReactTable
                  columns={columns}
                  data={filteredPosts}
                  pageRows={postsPerPage}
                />
              )}
            </div>
          </div>
          {/* Offcanvas to add new user */}
          {showAddNewUser && (
            <AddNewSpace
              setShowAddNewUser={setShowAddNewUser}
              addSpace={addSpace}
              setAddSpace={setAddSpace}
              space={space}
              setSpace={setSpace}
            />
          )}
        </div>
      </div>

      {/* <div className="content-backdrop fade" /> */}
      {showManageUsers && (
        <ShareModal
          setShowManageUsers={setShowManageUsers}
          showManageUsers={showManageUsers}
          usersData={usersData}
          selectedUsers={selectedUsers}
          usersRoles={usersRoles}
          spaceId={spaceId}
        />
      )}
    </div>
  );
};

export default ManageSpace;
