import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { usePermission } from "../../utils/usePermission";
import { isPlatformAdminUser } from "../../utils/utils";

const AccessWrapper = ({ hasAccess, children }) => {
  const currentSpace = useSelector((state) => state.defaultSpace.data);
  const isPlatformAdmin = isPlatformAdminUser(currentSpace);
  const canAccess = usePermission(currentSpace, hasAccess) || isPlatformAdmin;
  const memoizedCanAccess = useMemo(() => canAccess, [canAccess]);

  if (!memoizedCanAccess) {
    return (
      <span
        title={`You don't have ${hasAccess} permission`}
        className="permission-disabled"
      >
        {children}
      </span>
    );
  }

  return <>{children}</>;
};

export default AccessWrapper;
