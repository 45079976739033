import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../../Components/Banner/Banner";
import ReactTable from "../../Components/ReactTable/ReactTable";
import { CubeSpinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert } from "../../Components/Alerts/Alerts";
import styles from "./collections.module.css";

import isEmpty from "validator/es/lib/isEmpty";
import { filterByVal, getStandardErrMsg } from "../../utils/utils";

import {
  useDeleteCollectionMutation,
  useGetCollectionsQuery,
} from "../../services/collectionService";

import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";
import useConfirmDelete from "../../utils/useConfirmDelete";
import FillterWrapper from "../../Components/TableFilterWrapper/TableFillterWrapper";
import { useSelector } from "react-redux";
import AccessWrapper from "../../Components/AccessWrapper/AccessWrapper";

const CollectionsScreen = () => {
  const navigate = useNavigate();
  const currentSpace = useSelector((state) => state.defaultSpace.data);

  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);

  const { data, error, isFetching } = useGetCollectionsQuery(currentSpace);
  const [deletecollection] = useDeleteCollectionMutation();
  const confirmDelete = useConfirmDelete(deletecollection);

  const handleDelete = useCallback(
    (id) => {
      confirmDelete(id);
    },
    [confirmDelete]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "#",

        Cell: ({ cell }) => <i class="ti ti-folder"></i>,
      },
      {
        Header: "Collection",
        accessor: "collection_name",
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <div title={value} className={styles.collectionName}>
                {value}
              </div>
            </>
          );
        },
      },
      {
        Header: "Number of Doc",
        accessor: "num_of_docs",
      },
      {
        Header: "Created Time",
        accessor: "create_date_time",
      },
      {
        Header: "Updated Time",
        accessor: "update_date_time",
      },
      {
        Header: "Actions",
        Cell: ({ cell }) => (
          <>
            <AccessWrapper hasAccess="edit">
              <Link
                to={`/collection/view/${cell.row.original.collection_id}`}
                className="text-body"
              >
                <i class="ti ti-sm me-2 ti-arrow-right"></i>
              </Link>
            </AccessWrapper>
            <AccessWrapper hasAccess="delete">
              <Link
                to="#"
                className="text-body delete-record"
                onClick={() => handleDelete(cell.row.original.collection_id)}
              >
                <i className="ti ti-trash ti-sm mx-2" />
              </Link>
            </AccessWrapper>
          </>
        ),
      },
    ],
    [handleDelete]
  );

  useEffect(() => {
    if (!isEmpty(searchText) && data && data.length > 0) {
      const matchingRes = filterByVal(data, searchText);
      setFilteredPosts(matchingRes);
    } else if (data && data.length > 0) {
      setFilteredPosts([...data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  const handleAddNew = () => {
    navigate("/collection/new");
  };

  return (
    <ContentWrapper>
      <Banner>Collections</Banner>
      <div className="card mt-5">
        <FillterWrapper
          postsPerPage={postsPerPage}
          setPostPerPage={setPostPerPage}
          searchText={searchText}
          setSearchText={setSearchText}
          handleAddNew={handleAddNew}
          btnText="Collection"
        />
        {isFetching ? (
          <div className={styles.cubeLoader}>
            {" "}
            <CubeSpinner />
          </div>
        ) : error ? (
          <>
            <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
          </>
        ) : (
          <ReactTable
            columns={columns}
            data={filteredPosts}
            pageRows={postsPerPage}
          />
        )}
      </div>
    </ContentWrapper>
  );
};

export default CollectionsScreen;
