import React, { useEffect, useState } from "react";
import CurrentStepHeading from "../../Components/CurrentStepHeading/CurrentStepHeading";
import { Col, Row } from "react-bootstrap";
import FieldWrapper from "../../Components/FieldWrapper/FieldWrapper";
import InputField, {
  RadioField,
  SelectInput,
  Textarea,
} from "../../Components/Input/Input";
import FullscreenEditor from "../../Components/FullScreenEditor/FullScreenEditor.jsx";
import Select, { components } from "react-select";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdInfo } from "react-icons/md";
import makeAnimated from "react-select/animated";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../Components/Buttons/Buttons";
import { isJSON } from "validator";

import { generateReceiverConfig } from "./genrateReceiverConfig";
import {
  actionOptValues,
  agentOptValues,
  booleanOptions,
  humanOptionValue,
  modelOptValues,
  speakerOptionValue,
} from "../../utils/options";
import { isObject } from "../../utils/utils";
import { jsonEditorOptions } from "../../utils/jsonEditorOption";
import { Link } from "react-router-dom";
import AgentModifyModal from "./AgentModifyModal";

const animatedComponents = makeAnimated();

const WorkforceStep2 = ({
  handleEditorDidMount,
  receiverConfig,
  collectionData,
  modelData,
  actionData,
  isFetchingModel,
  isFetchingAction,
  setReceiverConfig,
  handlePrev,
  handleNext,
  agentsData,
  isFetchingAgents,
  error,
}) => {
  const [viewConfig, setViewConfig] = useState(false);
  const [openAgentModifyModal, setOpenAgentModifyModal] = useState(false);

  const [modifiedAgents, setModifiedAgents] = useState([]);

  const [selectedAgents, setSelectedAgents] = useState([]);

  const formValues = generateReceiverConfig(receiverConfig);
  const humanInputOptions = humanOptionValue();
  const speaker_selection_option = speakerOptionValue();
  const repeat_speaker_option = booleanOptions(true);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setReceiverConfig((prevConfig) => {
      let newValue = type === "checkbox" ? checked : value;

      // Check if the input type is number and parse the value accordingly
      if (type === "number") {
        newValue = parseFloat(value);
      }

      if (name in prevConfig) {
        // newValue = newValue.replace(/\s+/g, "_").replace(/[^a-zA-Z0-9_]/g, "");
        return {
          ...prevConfig,
          [name]: newValue,
        };
      } else if (name in prevConfig.config) {
        newValue =
          name === "name"
            ? newValue.replace(/\s+/g, "_").replace(/[^a-zA-Z0-9_]/g, "")
            : newValue;

        return {
          ...prevConfig,
          config: {
            ...prevConfig.config,
            [name]: newValue,
          },
        };
      } else if (name in prevConfig.groupchat_config) {
        return {
          ...prevConfig,
          groupchat_config: {
            ...prevConfig.groupchat_config,
            [name]: newValue,
          },
        };
      } else if (
        prevConfig.config.llm_config &&
        typeof prevConfig.config.llm_config === "object" &&
        name in prevConfig.config.llm_config
      ) {
        return {
          ...prevConfig,
          config: {
            ...prevConfig.config,
            llm_config: {
              ...prevConfig.config.llm_config,
              [name]: newValue,
            },
          },
        };
      } else if (
        prevConfig.config.code_execution_config &&
        typeof prevConfig.config.code_execution_config === "object" &&
        name in prevConfig.config.code_execution_config
      ) {
        return {
          ...prevConfig,
          config: {
            ...prevConfig.config,
            code_execution_config: {
              ...prevConfig.config.code_execution_config,
              [name]: newValue,
            },
          },
        };
      }
      return prevConfig;
    });
  };

  const modelOptions = modelOptValues(modelData, formValues.configList);

  // const modelOptions = modelData.map(({ model_id, model }) => ({
  //   value: model_id,
  //   label: `${model}`,
  //   isSelected: receiverConfig.config?.llm_config?.config_list.some(
  //     (model) => model.model_id === model_id
  //   ),
  // }));

  const modelDefaultValue = [
    ...modelOptions.filter((option) => option.isSelected),
  ];

  const agentOptions = agentOptValues(agentsData, formValues.agents);

  // const agentOptions = agentsData.map(({ agent_id, name }) => ({
  //   value: agent_id,
  //   label: `${name}`,
  //   isSelected: receiverConfig.groupchat_config?.agents?.some(
  //     (agent) => agent.agent_id === agent_id
  //   ),
  // }));

  const agentDefaultValue = [
    ...agentOptions.filter((option) => option.isSelected),
  ];

  const actionOptions = actionOptValues(actionData, formValues.skills);

  // const actionOptions = actionData.map(({ action_id, title }) => ({
  //   value: action_id,
  //   label: `${title}`,
  //   isSelected: receiverConfig.skills?.some(
  //     (action) => action.action_id === action_id
  //   ),
  // }));

  const actionDefaultValue = [
    ...actionOptions.filter((option) => option.isSelected), // Preselected options
  ];

  // const collectionOptions = collectionData.map(
  //   ({ collection_id, collection_name }) => ({
  //     value: collection_id,
  //     label: collection_name,
  //   })
  // );

  // collectionOptions.unshift({
  //   value: "",
  //   label: "Select",
  // });

  useEffect(() => {
    if (modifiedAgents && modifiedAgents.length > 0) {
      setReceiverConfig((prevConfig) => {
        let updatedAgents = [...prevConfig.groupchat_config.agents];

        let updatedModifiedAgents = prevConfig.groupchat_config.modifiedAgents
          ? [...prevConfig.groupchat_config.modifiedAgents]
          : [];

        console.log(
          "🚀 ~ setReceiverConfig ~ updatedModifiedAgents:",
          updatedModifiedAgents
        );

        modifiedAgents.forEach((newAgent) => {
          const existingAgentIndex = updatedAgents.findIndex(
            (agent) => agent.agent_id === newAgent.agent_id
          );
          updatedAgents[existingAgentIndex] = newAgent;

          const existingModifiedAgentIndex = updatedModifiedAgents.findIndex(
            (agent) => agent.agent_id === newAgent.agent_id
          );
          console.log(
            "🚀 ~ modifiedAgents.forEach ~ existingModifiedAgentIndex:",
            existingModifiedAgentIndex
          );

          if (existingModifiedAgentIndex !== -1) {
            updatedModifiedAgents[existingModifiedAgentIndex] = newAgent;
          } else {
            updatedModifiedAgents.push(newAgent);
          }
        });

        return {
          ...prevConfig,
          groupchat_config: {
            ...prevConfig.groupchat_config,
            agents: updatedAgents,
            modifiedAgents: updatedModifiedAgents,
          },
        };
      });
    }
  }, [modifiedAgents, setReceiverConfig]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setReceiverConfig((prevConfig) => {
      if (name === "use_llm_config") {
        return {
          ...prevConfig,
          config: {
            ...prevConfig.config,
            use_llm_config: checked,
            llm_config: checked
              ? {
                  cache_seed: null,
                  config_list: [],
                  temperature: 0.1,
                  timeout: null,
                }
              : false,
          },
        };
      } else if (name === "use_code_execution_config") {
        return {
          ...prevConfig,
          config: {
            ...prevConfig.config,
            use_code_execution_config: checked,
            code_execution_config: checked
              ? {
                  use_docker: false,
                  work_dir: null,
                }
              : false,
          },
        };
      }
      return prevConfig;
    });
  };

  const handleModelChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);

    const selectedItem = modelData.filter((item) =>
      selectedValues.includes(item.model_id)
    );
    // console.log("🚀 ~ handleModelChange ~ selectedItem:", selectedItem);

    const flattenedModel = selectedItem.map((item) => ({
      model_id: item.model_id,
      space_id: item.space_id,
      model: item.model,
      description: item.description,
      api_key: item.config.apiKey,
      api_type: item.config.apiType,
      api_version: item.config.apiVersion,
      base_url: item.config.baseUrl,
    }));
    setReceiverConfig((prevConfig) => ({
      ...prevConfig,
      config: {
        ...prevConfig.config,
        llm_config: {
          ...prevConfig.config.llm_config,
          config_list: flattenedModel,
        },
      },
    }));
  };

  const handleActionChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);

    const selectedItem = actionData.filter((item) =>
      selectedValues.includes(item.action_id)
    );

    const formattedSkills = selectedItem.map((item) => {
      const newFormat = { ...item };
      const selectedConfig =
        typeof item.actions_version[0].config === "string" &&
        isJSON(item.actions_version[0].config)
          ? JSON.parse(item.actions_version[0].config)
          : item.actions_version[0].config;

      newFormat.content = selectedConfig.content;

      delete newFormat["actions_version"];

      return newFormat;
    });
    setReceiverConfig((prevState) => ({
      ...prevState,
      skills: formattedSkills,
    }));
  };

  const handleAgentChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);

    let selectedItem = agentsData.filter((item) =>
      selectedValues.includes(item.agent_id)
    );
    setSelectedAgents(selectedItem);

    const alreadySelectedAgents = receiverConfig.groupchat_config.agents.map(
      (item) => item.agent_id
    );

    selectedItem = selectedItem.filter(
      (item) => !alreadySelectedAgents.includes(item.agent_id)
    );

    const agentWithModel = selectedItem.map((item) => {
      let currentConfig;

      const newItem = JSON.parse(JSON.stringify(item));

      currentConfig =
        typeof newItem.agent_versions[0].config === "string"
          ? JSON.parse(newItem.agent_versions[0].config)
          : newItem.agent_versions[0].config;

      if (currentConfig.config) {
        if (isObject(currentConfig.config.llm_config)) {
          const model = modelData.filter((model) =>
            currentConfig.config.llm_config.config_list.includes(model.model_id)
          );
          const flattenedModel = model.map((item) => ({
            model_id: item.model_id,
            space_id: item.space_id,
            model: item.model,
            description: item.description,
            api_key: item.config.apiKey,
            api_type: item.config.apiType,
            api_version: item.config.apiVersion,
            base_url: item.config.baseUrl,
          }));

          currentConfig.config.llm_config.config_list = flattenedModel;
        }

        newItem["config"] = currentConfig.config;
        newItem.config["name"] = currentConfig.name;
        newItem["type"] = currentConfig.type;
        newItem["skills"] = currentConfig.skills;
      }
      if (currentConfig.skills && currentConfig.skills.length > 0) {
        const actions = actionData.filter((action) =>
          currentConfig.skills.includes(action.action_id)
        );

        const formattedSkills = actions.map((item) => {
          const newFormat = { ...item };
          const selectedConfig =
            typeof item.actions_version[0].config === "string" &&
            isJSON(item.actions_version[0].config)
              ? JSON.parse(item.actions_version[0].config)
              : item.actions_version[0].config;

          newFormat.content = selectedConfig.content;

          delete newFormat["actions_version"];

          return newFormat;
        });

        newItem["skills"] = formattedSkills;
      }
      delete newItem["agent_versions"];
      delete newItem["name"];

      return newItem;
    });

    setReceiverConfig((prevConfig) => {
      // Keep only those agents in prevConfig that are not in selectedValues
      const existingAgents = prevConfig.groupchat_config.agents.filter(
        (agent) => selectedValues.includes(agent.agent_id)
      );

      const updatedModifiedAgents =
        prevConfig.groupchat_config?.modifiedAgents?.filter((agent) =>
          selectedValues.includes(agent.agent_id)
        );

      return {
        ...prevConfig,
        groupchat_config: {
          ...prevConfig.groupchat_config,
          agents: [...existingAgents, ...agentWithModel],
          modifiedAgents: updatedModifiedAgents,
        },
      };
    });

    // setReceiverConfig((prevConfig) => ({
    //   ...prevConfig,
    //   groupchat_config: {
    //     ...prevConfig.groupchat_config,
    //     agents: [...prevConfig.groupchat_config.agents, ...agentWithModel],
    //   },
    // }));
  };

  const handleEditorChange = (value) => {
    try {
      const parsedValue = JSON.parse(value);
      setReceiverConfig(parsedValue);
    } catch (error) {
      console.error("Invalid JSON input", error);
    }
  };

  const handleNextClick = () => {
    handleNext(3);
  };
  const handlePrevClick = () => {
    handlePrev(1);
  };

  const CustomAgentOptions = (props) => {
    return (
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {props?.data?.space_id === -1 ? (
            <>
              <span
                style={{
                  color: "#6158ca",
                }}
              >
                {props?.data?.label}
              </span>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Platform Resource</Tooltip>}
              >
                <span style={{ marginLeft: "10px", cursor: "pointer" }}>
                  <MdInfo style={{ color: "#6158ca" }} />
                </span>
              </OverlayTrigger>
            </>
          ) : (
            <span>{props?.data?.label}</span>
          )}
        </div>
      </components.Option>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CurrentStepHeading
          title="Receiver"
          titleSummary="Enter receiver details"
        />
        <div className="text-center mt-3">
          <button
            type="button"
            className="btn btn-sm btn-primary waves-effect waves-light"
            onClick={() => setViewConfig(!viewConfig)}
          >
            {viewConfig ? "Hide " : "View "} JSON <i class="ti ti-code"></i>
          </button>
        </div>
      </div>
      {!viewConfig ? (
        <>
          <FieldWrapper>
            <Textarea
              value={formValues.systemMessage}
              placeholder="Planner. Thank like a Finance Manager for a Quick Service Restaurant (QSR) chain. Suggest a plan. The plan may involve an engineer who can write python code, an executor who execute code, critic to review the code and a writer who doesn't write code but write the summary/insights of the findings. Explain the plan first. Be clear which step is performed by the engineer, which step is performed by the executor, which step is performed by the critic and which step is performed by the writer. You pass the plan to the engineer."
              label="System Message"
              name="system_message"
              onChange={handleChange}
              rows={7}
            />
          </FieldWrapper>

          <Row className="mb-3 ">
            <Col>
              <FieldWrapper errorMsg={error.name}>
                <InputField
                  type="text"
                  value={formValues.name}
                  placeholder="Name"
                  label="Name"
                  name="name"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>
            <Col>
              <FieldWrapper>
                <InputField
                  type="text"
                  value={formValues.defaultAutoReply}
                  placeholder="default_auto_reply"
                  label="Default Auto Reply"
                  name="default_auto_reply"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>
          </Row>

          <Row className="mb-3 ">
            <Col>
              <FieldWrapper>
                <InputField
                  type="text"
                  value={formValues.isTerminationMsg}
                  placeholder="is_termination_msg"
                  label="Is Termination Msg"
                  name="is_termination_msg"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>

            <Col>
              <RadioField
                type="checkbox"
                value={formValues.useCodeExecutionConfig}
                placeholder=""
                label="Code Execution Config"
                name="use_code_execution_config"
                onChange={handleCheckboxChange}
                mainLabel="Code Execution Config"
              />
            </Col>
          </Row>

          {formValues.useCodeExecutionConfig && (
            <Row className="mb-3 ">
              <Col>
                <FieldWrapper>
                  <InputField
                    type="text"
                    value={formValues.workDir}
                    placeholder="work_dir"
                    label="Working Dir"
                    name="work_dir"
                    onChange={handleChange}
                  />
                </FieldWrapper>
              </Col>
              <Col>
                <RadioField
                  type="checkbox"
                  value={formValues.useDocker}
                  placeholder=""
                  label="Use Docker"
                  name="use_docker"
                  onChange={handleChange}
                  mainLabel="Use Docker"
                />
              </Col>
            </Row>
          )}

          <Row className="mb-3 ">
            <Col>
              <FieldWrapper>
                <InputField
                  type="text"
                  value={formValues.type}
                  placeholder="type"
                  label="Type"
                  name="type"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>
            <Col>
              <FieldWrapper>
                <label htmlFor="input-field">Select Action</label>
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  options={actionOptions}
                  onChange={handleActionChange}
                  isLoading={isFetchingAction}
                  components={animatedComponents}
                  defaultValue={actionDefaultValue}
                />
              </FieldWrapper>
            </Col>
          </Row>

          {/* {formValues.useLlmConfig && ( */}
          <Row>
            <Col>
              <FieldWrapper>
                <InputField
                  type="number"
                  value={formValues.cacheSeed}
                  placeholder="cache_seed"
                  label="Cache Seed"
                  name="cache_seed"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>

            <Col>
              <FieldWrapper errorMsg={error.model}>
                <label htmlFor="input-field">Select Model</label>
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  options={modelOptions}
                  onChange={handleModelChange}
                  isLoading={isFetchingModel}
                  components={animatedComponents}
                  defaultValue={modelDefaultValue}
                />
              </FieldWrapper>
            </Col>
          </Row>
          <>
            <Row className="mb-3 ">
              <Col>
                <FieldWrapper>
                  <InputField
                    type="Number"
                    value={formValues.temperature}
                    placeholder="0.1"
                    label="Temperature"
                    name="temperature"
                    onChange={handleChange}
                  />
                </FieldWrapper>
              </Col>
              <Col>
                <FieldWrapper>
                  <InputField
                    type="number"
                    value={formValues.timeout}
                    placeholder="timeout"
                    label="Timeout"
                    name="timeout"
                    onChange={handleChange}
                  />
                </FieldWrapper>
              </Col>
            </Row>
          </>
          {/* )} */}

          <Row className="mb-3 ">
            <Col>
              <SelectInput
                options={humanInputOptions}
                value={formValues.humanInputMode}
                onChange={handleChange}
                label="Human Input Mode"
                name={"human_input_mode"}
              />
            </Col>
            <Col>
              <FieldWrapper>
                <InputField
                  type="number"
                  value={formValues.maxConsecutiveAutoReply}
                  placeholder="max_consecutive_auto_reply"
                  label="Max Consecutive Auto Reply"
                  name="max_consecutive_auto_reply"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>
          </Row>

          <FieldWrapper>
            <Textarea
              value={formValues.description}
              placeholder="Default Group  Workflow"
              label="Description"
              name="description"
              onChange={handleChange}
            />
          </FieldWrapper>

          <hr class="my-6 mx-n4" />

          {/*
           ** GroupChat config Start
           */}

          <CurrentStepHeading title="Group Chat Config" titleSummary="" />
          <Row className="mb-3 ">
            <Col>
              <FieldWrapper>
                <InputField
                  type="text"
                  value={formValues.adminName}
                  placeholder="admin_name"
                  label="Admin Name"
                  name="admin_name"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>
            <Col>
              <FieldWrapper errorMsg={error.agents}>
                <label htmlFor="input-field">Select Agents</label>
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  options={agentOptions}
                  onChange={handleAgentChange}
                  isLoading={isFetchingAgents}
                  components={{
                    ...animatedComponents,
                    Option: CustomAgentOptions,
                  }}
                  defaultValue={agentDefaultValue}
                />

                {(selectedAgents.length > 0 ||
                  receiverConfig.groupchat_config.agents.length > 0) && (
                  <Link
                    to={"#"}
                    onClick={() =>
                      setOpenAgentModifyModal(!openAgentModifyModal)
                    }
                  >
                    <small style={{ color: "#1b09ec" }} className="mt-3">
                      <i className="ti ti-edit"></i> Modify Agents
                    </small>
                  </Link>
                )}

                {openAgentModifyModal && (
                  <AgentModifyModal
                    // agentOptions={agentOptions}
                    setOpenAgentModifyModal={setOpenAgentModifyModal}
                    selectedAgents={selectedAgents}
                    modifiedAgents={modifiedAgents}
                    setModifiedAgents={setModifiedAgents}
                    modelData={modelData}
                    actionData={actionData}
                    agents={formValues.agents}
                    receiverConfig={receiverConfig}
                    agentsData={agentsData}
                  />
                )}
              </FieldWrapper>
            </Col>
          </Row>

          <Row className="mb-3 ">
            <Col>
              <FieldWrapper>
                <InputField
                  type="number"
                  value={formValues.maxRound}
                  placeholder="max_round"
                  label="Max Round"
                  name="max_round"
                  onChange={handleChange}
                />
              </FieldWrapper>
            </Col>
            <Col>
              <SelectInput
                options={speaker_selection_option}
                value={formValues.speakerSelectionMethod}
                onChange={handleChange}
                label="Speaker Selection Method"
                name={"speaker_selection_method"}
              />
            </Col>
            <Col>
              <SelectInput
                options={repeat_speaker_option}
                value={formValues.allowRepeatSpeaker}
                onChange={handleChange}
                label="Allow Repeat Speaker"
                name={"allow_repeat_speaker"}
              />
            </Col>
          </Row>
        </>
      ) : (
        <FullscreenEditor
          height="50vh"
          defaultLanguage="json"
          defaultValue={receiverConfig && JSON.stringify(receiverConfig)}
          theme="vs-dark"
          options={jsonEditorOptions}
          onMount={handleEditorDidMount}
          onChange={handleEditorChange}
        />
      )}

      {viewConfig && (
        <small style={{ color: "#1b09ec" }} className="mt-3">
          <i class="ti ti-info-circle"></i> Please note that the final JSON
          values will be taken from the code block below. You can add additional
          values as well.
        </small>
      )}

      <div className="col-12 d-flex justify-content-between mt-3">
        <SecondaryButton handleClick={handlePrevClick}>
          <i className="ti ti-arrow-left me-sm-1 me-0" /> Previous
        </SecondaryButton>

        <PrimaryButton handleClick={handleNextClick}>
          Next <i className="ti ti-arrow-right" />
        </PrimaryButton>
      </div>
    </>
  );
};

export default WorkforceStep2;
