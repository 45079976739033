import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../../Components/Banner/Banner";
import ReactTable from "../../Components/ReactTable/ReactTable";
import { CubeSpinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert } from "../../Components/Alerts/Alerts";
import styles from "./agent.module.css";
import { MdInfo } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import isEmpty from "validator/es/lib/isEmpty";
import {
  filterByVal,
  truncateDescription,
  getStandardErrMsg,
  isPlatformAdminUser,
} from "../../utils/utils";

import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";
import useConfirmDelete from "../../utils/useConfirmDelete";
import FillterWrapper from "../../Components/TableFilterWrapper/TableFillterWrapper";
import {
  useAddAgentMutation,
  useDeleteAgentMutation,
  useGetAgentsQuery,
} from "../../services/agentsService";
import { useSelector } from "react-redux";
import useFilteredData from "../../utils/useFilteredData";
import CopyRowModal from "../../Components/CopyRowModal/CopyRowModal";
import AccessWrapper from "../../Components/AccessWrapper/AccessWrapper";
import { heliosBot } from "../../constants/constants";

const AgentScreen = () => {
  const navigate = useNavigate();
  const currentSpace = useSelector((state) => state.defaultSpace.data);
  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  // const [filteredPosts, setFilteredPosts] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { data, error, isFetching } = useGetAgentsQuery(currentSpace);
  const [deleteDatabase] = useDeleteAgentMutation();
  const confirmDelete = useConfirmDelete(deleteDatabase);
  const [addAgent, addAgentInfo] = useAddAgentMutation();
  const isPlatformAdmin = isPlatformAdminUser(currentSpace);

  const handleDelete = useCallback(
    (id) => {
      confirmDelete(id);
    },
    [confirmDelete]
  );

  const handleCopyClick = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const handleCopy = async (newName) => {
    const copiedData = { ...selectedRow };

    const body = {
      agent_id: null,
      agent_version_id: null,
      version: "1.0",
      stage: copiedData.agent_versions[0].stage,
      space_id: currentSpace,
      name: newName,
      description: copiedData.description,
      framework: copiedData.framework,
      share: copiedData.share.toString(),
      parent_id: copiedData.parent_id,
      platform_default: copiedData.platform_default,
      config: JSON.parse(copiedData.agent_versions[0].config),
      type: "",
    };

    await addAgent(body);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "agent_id",
      },
      {
        Header: "Title",
        accessor: "name",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {row.original.space_id === -1 ? (
              <>
                <span style={{ color: "#6158ca" }}>{row.original.name}</span>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Platform Resource</Tooltip>}
                >
                  <span style={{ marginLeft: "10px", cursor: "pointer" }}>
                    <MdInfo style={{ color: "#6158ca" }} />
                  </span>
                </OverlayTrigger>
              </>
            ) : (
              <span>{row.original.name}</span>
            )}
          </div>
        ),
      },
      {
        Header: "Framework",
        accessor: "framework",
      },
      {
        Header: "Shared",
        Cell: ({ cell }) =>
          cell.row.original.share === "true" || cell.row.original.share === true
            ? "Yes"
            : "No",
      },
      {
        Header: "Desc",
        accessor: "description",
        Cell: ({ cell }) => (
          <> {truncateDescription(cell.row.original.description, 50)}</>
        ),
      },
      {
        Header: "Agent",
        Cell: ({ cell }) => (
          <>
            <Link
              to={`${heliosBot}/agents?id=${cell.row.original.agent_id}`}
              className="text-body"
              target="_blank"
            >
              <i className="ti ti-player-play ti-sm me-2"></i>
            </Link>
            <span
              title={
                !isPlatformAdmin && cell.row.original.space_id === -1
                  ? `You don't have edit permission`
                  : ""
              }
              className={
                !isPlatformAdmin && cell.row.original.space_id === -1
                  ? "permission-disabled"
                  : ""
              }
            >
              <Link
                to={`/agent/edit/${cell.row.original.agent_id}`}
                className="text-body"
              >
                <i className="ti ti-edit ti-sm me-2" />
              </Link>
            </span>
            <AccessWrapper hasAccess="create">
              <Link
                to="#"
                className="text-body"
                onClick={() => handleCopyClick(cell.row.original)}
              >
                <i className="ti ti-copy ti-sm mx-2"></i>
              </Link>
            </AccessWrapper>
            <AccessWrapper hasAccess="delete">
              <Link
                to="#"
                className="text-body delete-record"
                onClick={() => handleDelete(cell.row.original.agent_id)}
              >
                <i className="ti ti-trash ti-sm mx-2" />
              </Link>
            </AccessWrapper>
          </>
        ),
      },
    ],
    [handleDelete]
  );

  const filteredPosts = useFilteredData(data, searchText, filterByVal);

  const handleAddNew = () => {
    navigate("/agent/new");
  };

  return (
    <ContentWrapper>
      <Banner>Agents</Banner>
      <div className="card mt-5">
        <FillterWrapper
          postsPerPage={postsPerPage}
          setPostPerPage={setPostPerPage}
          searchText={searchText}
          setSearchText={setSearchText}
          handleAddNew={handleAddNew}
          btnText="Agent"
        />
        {isFetching ? (
          <div className={styles.cubeLoader}>
            {" "}
            <CubeSpinner />
          </div>
        ) : error ? (
          <>
            <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
          </>
        ) : (
          <ReactTable
            columns={columns}
            data={filteredPosts}
            pageRows={postsPerPage}
          />
        )}
      </div>
      {selectedRow && (
        <CopyRowModal
          show={showModal}
          onHide={() => setShowModal(false)}
          onCopy={handleCopy}
          existingNames={filteredPosts.map((agent) => agent.name)}
          modalTitle="Copy Agent"
          fieldName="New Agent name"
          prefilledName={`${selectedRow.name}_1`}
          isLoading={addAgentInfo?.isLoading || false}
        />
      )}
    </ContentWrapper>
  );
};

export default AgentScreen;
